<template>
  <div>
    <h1 class=" my-3 px-3 p-sm-0 py-0 py-md-3 page-heading w-100">ANYTASK PLATFORM – INTELLECTUAL PROPERTY COMPLAINTS PROCEDURE</h1>
    <div id="terms" class="container bg-white pt-2 px-4 text-left pb-3">
      <p>The AnyTask Platform allows Buyers and Sellers to engage directly with each other in order to buy and sell digital products and services. </p>
      <p>We require that any User Generated Content must meet all the Content Standards that we specify from time to time.</p>
      <p>User Generated Content is not actively moderated or approved of by us and its presence on the AnyTask Platform cannot be considered or deemed to be an endorsement or approval of it by us.</p>
      <p>The Task matching services that we provide, for clarity, are whereby Sellers and Buyers are able to search for each other’s User Generated Content and NOT by way of any intervention or positive action on our part to co-ordinate users requirements.</p>
      <p>If you believe that any User Generated Content appearing on the AnyTask Platform infringes any of your intellectual property rights as the rights owner, please complete the form set out below <b>(NOTICE OF INFRINGEMENT OF INTELLECTUAL PROPERTY)</b> which is the first step in our procedure (outlined below) in dealing with your complaint and send it to us through: <a href="https://support.anytask.com" target="_blank">https://support.anytask.com</a> and by doing so, you will be deemed to have accepted our requirements set out below.</p>
      <p>This process and these requirements do NOT apply to any Task related issues between Buyers and Sellers, please use the mechanism within the AnyTask Platform or contact <a href="https://support.anytask.com" target="_blank">https://support.anytask.com</a>.</p>
      <div class="box p-2 mb-2">
        <p><b>PLEASE NOTE:</b></p>
        <p><b>Incomplete, inaccurate or unsigned notices will not be accepted and ALL fields MUST be completed for us to be able to commence a take-down investigation.</b></p>
        <p><b>As part of our investigations, we will disclose extracts from your complaint to the relevant user that you allege is infringing your rights in order to allow them to respond directly to you.</b></p>
      </div>
      <p><b>Fraudulent complaints will be reported to the relevant law enforcement authorities in your jurisdiction and civil proceedings will be issued to recover any costs, or expenses incurred including legal fees.</b></p>
      <h4 class="text-center"><u><b>NOTICE OF INFRINGEMENT OF INTELLECTUAL PROPERTY</b></u></h4>
      <table class="table table-striped table-bordered">
        <tbody>
        <tr>
          <td>Name:</td>
          <td>[Your full name]</td>
        </tr>
        <tr>
          <td>Organisation:</td>
          <td>[Full name of your organisation including registered company number and registered office address if applicable]</td>
        </tr>
        <tr>
          <td>Your role:</td>
          <td>[Within the organisation]/[author/inventor of intellectual property]/[owner of intellectual property]</td>
        </tr>
        <tr>
          <td>Full postal address:</td>
          <td>[Please include full building names and numbers, street names, county, country and postcode details – P.O Boxes are not acceptable]</td>
        </tr>
        <tr>
          <td>Email address:</td>
          <td>[Your full email address]</td>
        </tr>
        <tr>
          <td>Telephone number:</td>
          <td>[Your full telephone number together with any international dialling codes]</td>
        </tr>
        <tr>
          <td>Intellectual Property description:</td>
          <td>[Please give a full description of what material you believe is your protected intellectual property and which is being infringed.]</td>
        </tr>
        <tr>
          <td>Registered Intellectual Property:</td>
          <td>[Please let us have any registration numbers for any registered intellectual property that you claiming is being infringed]</td>
        </tr>
        <tr>
          <td>Proof of ownership of intellectual property if not registered:</td>
          <td>[Please let us have evidence of your ownership of the intellectual property in question]</td>
        </tr>
        <tr>
          <td>Evidence of infringement:</td>
          <td>[Please let us have full details of how you believe that any user has infringed your intellectual property]</td>
        </tr>
        <tr>
          <td>Specific location of infringing material on the AnyTask Platform:</td>
          <td>[Please be specific and include links; URLs; user identifiers; timed screen shots; so that we can pinpoint the material that you believe belongs to you]</td>
        </tr>
        <tr>
          <td>Confirmation of context of complaint and sharing of information:</td>
          <td>I confirm that the details of this complaint are true, accurate and complete and not misleading and acknowledge that the details of this complaint will be shared with the alleged infringing party.</td>
        </tr>
        <tr>
          <td colspan="2"> <b>The penalties for signing a statement of truth without an honest belief in the truth of the facts being verified are potentially severe. A person who makes a false statement in litigation in an attempt to interfere with the course of justice will be in contempt of court, which is punishable by a prison sentence of up to two years.</b></td>
        </tr>
        <tr>
          <td><b>STATEMENT OF TRUTH:</b></td>
          <td><b><u>I am the rights owner and I believe that the facts stated in this NOTICE OF INFRINGEMENT OF INTELLECTUAL PROPERTY are true.</u></b></td>
        </tr>
        <tr>
          <td>Physical / Electronic signature:</td>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td>Date:</td>
          <td>&nbsp;</td>
        </tr>
        </tbody>
      </table>
      <ol class="pt-2">
        <li>
          <b>Process for Complainants</b>
          <ol>
            <li>
              Once the fully completed NOTICE OF INFRINGEMENT OF INTELLECTUAL PROPERTY is received, we will:
              <ol>
                <li>ask you to validate your identity and credentials; and once successfully completed we will</li>
                <li>send extracts from your complaint to the alleged infringing user to enable them to respond directly to you.</li>
              </ol>
            </li>
            <li>The user in question will be given an opportunity to respond to the allegations and make representations as to any permissions that they may have in respect of the intellectual property in question. </li>
            <li>If the user fails to respond to you or us within 14 days, we will make our assessment based on the information within our control.</li>
            <li>In the event that the evidence that you provide in support of your complaint is incontrovertible, we will remove any infringing content from the AnyTask Platform prior to any response from the user, save as may be required to be retained by us for audit or regulatory or legal purposes.</li>
            <li>We reserve our rights against any complainant making any fraudulent claims alleging intellectual property infringement.</li>
          </ol>
        </li>
        <li>
          <b>Civil or criminal proceedings</b>
          <ol>
            <li>If you require our assistance in any civil or criminal proceedings against any infringing party, we will be entitled to charge you for such assistance, including for our time and any expenses and/or legal fees that we incur.</li>
          </ol>
        </li>
        <li>
          <b>Confidentiality</b>
          <ol>
            <li>You shall keep confidential and not disclose any communications that we have with you in relation to any complaint made by you to any third party other than your legal advisers (such confidentiality and non-disclosure obligations to survive the conclusion of any investigation period) provided that this requirement shall not extend to information which was rightfully in your possession prior to making your complaint or which is already public knowledge or becomes so at a future date (otherwise than as a result of a breach of this requirement) or which is trivial or obvious. </li>
            <li>All data and information uploaded to and processed on the AnyTask Platform by you may be used by us for internal monitoring and business purposes. </li>
            <li>Whilst we will take reasonable steps to maintain the confidentiality of your information, you acknowledge that transmission of information across the internet cannot be guaranteed as being completely secure and accordingly you upload and transmit any files to us at your own risk. We shall have no liability to you in the event of any unauthorised access to your information as a result of any malicious activity by any third party and/or any failure of any software or hardware infrastructure.</li>
            <li>We shall share any information about you and/or any complaint you make as we may be required to by law.</li>
            <li>You agree to indemnify us and keep us indemnified from all losses, liabilities, fines, penalties or expenses we suffer as a result of your breach of these confidentiality requirements.</li>
          </ol>
        </li>
        <li>
          <b>Governing law and jurisdiction</b>
          <ol>
            <li>In submitting your NOTICE OF INFRINGEMENT OF INTELLECTUAL PROPERTY you agree that any dispute or claim arising out of any allegation of infringement of intellectual property rights or in connection with any process or procedure adopted by us shall be governed by and construed in accordance with the law of England (which is the jurisdiction we have chosen due to the global nature of our business).</li>
            <li>You and we irrevocably agree that the courts of England shall have exclusive jurisdiction to settle any dispute or claim that arises out of or in connection with any allegation of infringement of intellectual property rights or in connection with any process or procedure adopted by us.</li>
          </ol>
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IntellectualProperty'
}
</script>

<style scoped>
  .box {
    border: 2px solid black;
  }
  #terms {
    text-align: left;
    margin-top: 1px;
    margin-bottom: 4rem;
  }
  #terms h1 {
    text-align: center;
  }
  #terms ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
  }
  #terms ol > li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
  }
  #terms ol > li:before {
    content: counters(item, ".") ". ";
    display: table-cell;
    padding-right: 0.6em;
  }
  #terms li ol > li {
    margin: 0.5rem 0 0.5rem 0;
  }
  #terms li ol > li:before {
    content: counters(item, ".") " ";
  }
</style>
